body {
  min-height: 100vh;
  background-image: url(https://i.imgur.com/seF1VQX.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App {
  min-height: 100vh;
  font-family: 'Abel', sans-serif;
  letter-spacing: 0.05em;
}
.App-header {
  display: flex;
  color: white;
}

.form-text {
  color: rgb(200, 200, 200, 1)!important;
}

ul {
  list-style-type: "- ";
}

p a {
  color: rgb(200, 200, 200, 1);
  text-decoration: none;
}

p a:hover {
  color: rgba(230, 230, 230, 1);
}

h1 a {
  color: rgb(255, 255, 255, 1);
  text-decoration: none;
}

h1 a:hover {
  color: rgba(230, 230, 230, 1);
}

code {
  color: rgba(200, 200, 200, 1)!important;
}

@media only screen and (max-width: 991px) {
  .project-image {
    width: 100%;
  }
  .project-info {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .project-image {
    width: 30%;
  }
  .project-info {
    width: 70%;
  }
}