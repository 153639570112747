.puzzle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.puzzle-board {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.puzzle-row {
    display:flex;
  }
  
  .puzzle-square {
    display: flex;
    width: 3em;
    height: 3em;
    font-size: 1em;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: #f0f0f0;
  }
  
  .puzzle-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .col-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 1.5em;
  }
  .row-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    width: 1.5em;
  }
  
  #col-heading-holder {
    display:flex;
  }
  
  #heading-frame-corner {
    width: 1.5em;
    height: 1.5em;
  }